<template>
	<div class="view view-login">
		<div class="bg"></div>
		<clouds />
		<div class="scroll" v-scrollbar>
			<div>
				<div class="ticket-wrap">
					<div class="ticket">
						<div class="ticket-top"></div>

						<div class="ticket-content">
							<component :is="'page-' + page" />
						</div>

						<div class="ticket-bottom">
							<div class="bus"></div>
							<div class="barcode"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Clouds from '@/components/world/clouds'
import PageMenu from '@/components/start/page-menu'
import PageLogin from '@/components/start/page-login'
import PageSignup from '@/components/start/page-signup'
import PageForgot from '@/components/start/page-forgot'
import PagePlay from '@/components/start/page-play'
import PageForgotSent from '@/components/start/page-forgot-sent'
import PageSignupSent from '@/components/start/page-signup-sent'

export default {
	name: 'view-login',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		page() {
			if ([ 'login', 'signup', 'forgot', 'play', 'forgot-sent', 'signup-sent' ].includes(this.$route.name)) return this.$route.name
			return 'menu'
		}
	},
	mounted() {
		if (!localStorage.getItem('jeuRapide')) {
			this.$store.commit('EDIT_APP_STATE', { main_loader: false })
		} else {
			this.$router.push({ name: 'world' })
		}
	},
	components : { Clouds, PageMenu, PageLogin, PageSignup, PageForgot, PagePlay, PageForgotSent, PageSignupSent }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


.view-login
	height 100%
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background url(../assets/img/province_map.svg) center center no-repeat
		background-size cover

	.scroll
		height 100%
		width 100%

		.ticket-wrap
			height 100%
			width 100%
			flex center center

	.ticket
		flex space-between center
		flex-direction column
		width calc(100% - 40px)
		// min-height 650px
		max-width 350px
		padding 0 0 32px 0

		// background url(../assets/img/bus-ticket.svg) center center no-repeat
		// background-size contain
		
		.ticket-top
			width 100%
			height 64px
			background url(../assets/img/bus-ticket.svg) top left repeat-x
			background-size 100% auto
			
		.ticket-content
			width 100%
			min-height 300px
			background-color #fff
			padding 16px 0

			>>> .page
				// padding 56px 24px 32px 24px
				// height 80%
				// padding 64px 48px 48px 48px
				padding 0 32px
				width 100%
				.page-title
					height 32px
					margin 0 0 32px 0
					flex center center
					font-weight 500
					font-size 2.4rem
					color #2E7BB5
					text-transform uppercase
				.form
					// height calc(100% - 64px)
					height auto
					.line
						margin 0 0 16px 0
						.input
							height 48px
							width 100%
							padding 0 24px
							background transparent
							border 1px solid #2f7bb5
							border-radius 40px
							box-shadow 0 2px 4px alpha(#000, 25%)
							outline none
							appearance none
							font inherit
							color dark
							line-height 40px
							font-weight 500
							&:focus
								transform translateY(1px)
								background-color alpha(#2f7bb5, 10%)
								border 1px solid #2f7bb5
								box-shadow none
								box-shadow inset 0 2px 4px alpha(#000, 15%)
						> .error
							display block
							margin 4px 0 0 0
							padding 3px 0 3px 32px
							background url(../assets/img/alert-circle.svg) left center no-repeat
							background-size 24px 24px
							line-height 18px
							color red
							font-weight 500
						.info
							color alpha(#fff, 75%)
							text-align center
							// text-transform uppercase
							font-size 1.6rem
							line-height 20px
					.ui-button
						box-shadow 0 2px 4px alpha(#000, 25%)
					.buttons
						// position absolute
						// left 0
						// bottom 0
						// right 0
						flex space-between center
						.ui-button
							width calc(50% - 8px)
							.button
								padding 0
								font-weight 700
								text-transform uppercase
		.ticket-bottom
			width 100%
			height 156px
			flex center center
			flex-direction column
			background-color alpha(red, 50%)
			background url(../assets/img/bus-ticket.svg) bottom center no-repeat
			background-size 100% auto
			.bus
				width 64px
				height 24px
				margin 0 0 8px 0
				background url(../assets/img/little-bus.svg) center center no-repeat
				background-size auto 100%
			.barcode
				width 100%
				height 48px
				background url(../assets/img/barcode.svg) center center no-repeat
				background-size auto 100%



@media (max-width: 352px)
	.view-login
		.scroll
			.ticket-wrap
				.ticket
					.ticket-bottom
						height 132px

@media (max-height: 750px)
	.view-login
		.scroll
			.ticket-wrap
				display block
				.ticket
					margin 50px auto

</style>
