<template>
	<div class="page page-play">
		<div class="page-title"></div>
		<div class="form">
			<p class="disclaimer">Jouer sans se connecter ou créer un compte ne permet pas d'enregistrer sa progression.</p>


			<div class="buttons">
				<ui-button :to="{ name: 'signup' }" class="signup-button" width="100%" color="dark">Créer un compte</ui-button>
				<ui-button v-hammer:tap="() => $router.go(-1)" color="red">Retour</ui-button>
				<ui-button v-hammer:tap="() => play()" color="green">Confirmer</ui-button>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'page-play',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([  ])
	},
	methods: {
		async play() {
      await this.$store.dispatch('noNeedForStart')
      this.$store.commit('EDIT_APP_STATE', { main_loader: true })
			await this.$router.push({ name: 'world' })
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.page.page-play
	.disclaimer
		font-size 2.4rem
		color #000
		line-height 30px
		text-align center
		text-transform uppercase
	.buttons
		.signup-button
			position absolute
			left 0
			bottom calc(100% + 16px)


</style>
