<template>
  <div
      class="form-btn-base form-btn-themed"
      v-hammer:tap="() => openFancybox()"
      v-html="html"
  ></div>
</template>

<script>
	import { mixinFormElements } from '@/js/mixins/form-elements'
	import Apollo from "@/apollo/apollo-client";

	export default {
		name: 'formBtn',
		mixins: [ mixinFormElements ],
		data() {
			const BTN = 'BTN-'
			const FANCYBOX = 'FANCYBOX-'
			return {
				BTN,
				FANCYBOX
			}
		},
		methods: {
			async openFancybox() {
				const { data: { tags = [] } } = this
				if (!tags || !tags.length) return null
				const { BTN, FANCYBOX } = this
				const func = tags
					.reduce((a, c) => {
						const [ , prefix, extra ] = c.match(/^(.+\-)(.+)/)
						if ([ BTN, FANCYBOX ].includes(prefix) && !a) return extra
						return a
					}, '')
				if (this[func]) {
					const { src, externalUrl} = await this[func]()
					if (externalUrl) {
						this.open_url(externalUrl)
					} else if (src) {
						$.fancybox.open(src, {
							protect: true,
							infobar: false,
							toolbar: false,
							type: 'html',
						}, 0)
					}
				}
			},
      open_url(url) {
        if (url)
          window
            .open(url, '_blank')
            .focus()
      },
		},
  }
</script>

<style lang="stylus">

@media (min-width: 700px)
	.fancybox-content
		max-width 90% !important
@media (min-width: 950px)
	.fancybox-content
		max-width 80% !important
@media (min-width: 1200px)
	.fancybox-content
		max-width 70% !important
@media (min-width: 1450px)
	.fancybox-content
		max-width 60% !important
@media (min-width: 1700px)
	.fancybox-content
		max-width 50% !important
</style>
