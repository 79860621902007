import { allBeginsWith, applyValuesOnString } from '@/js/utils/utils'
import { mapGetters } from 'vuex'
import Apollo from "@/apollo/apollo-client";

export const mixinFormElements = {
	props: [ 'data', 'isWithConfirmation', 'context' ],
	data() {
		const RULE = 'RULE-'
		return {
			RULE,
			value: null
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		selectItems() {
			const { $props: { context = {}, data: { selectableElements = [] } = {} } = {} } = this
			return [ {
				option: 'Choisi une des options',
				value: null
			}, ...selectableElements
				.map(x => {
					const { id:value, option: { html:option } } = x
					return {
						option,
						value
					}
				}) ]
		},
		html() {
			const { $props: { context = {}, data: { html:text = '' } = {} } = {} } = this
			return applyValuesOnString.call(context, { text })
		},
		formElementId() {
			const { $props: { data: { formElementId } = {} } = {} } = this
			return formElementId
		},
		slug() {
			const { $props: { data: { slug } = {} } = {} } = this
			return slug
		},
		label() {
			const { $props: { data: { label } = {} } = {} } = this
			return label
		},
		rules() {
			const { RULE, $props: { isWithConfirmation = false, data: { isRequired, elementType, slug, tags } = {} } = {} } = this
			let rules = ''
			rules += isRequired
				? 'required'
				: ''
			switch(elementType) {
				case 'Content::Form::PasswordInput':
					if (!isWithConfirmation) rules += '|strongPassword'
					break;
				case 'Content::Form::AcceptInput':
					rules += isRequired
						? '|is_true'
						: ''
					break
			}
			if (isWithConfirmation) rules += `|confirmed:${slug}`
			const rule = (allBeginsWith(tags, RULE)[0] || '').replace(RULE, '')
			if (rule) rules += `|${rule}`
			return rules
		},
		css() {
			const { $props:{ data:{ tags = []} = {} } = {}} = this
			return  (allBeginsWith(tags, 'CSS-')[0] || '').replace('CSS-', '')
		}
	},
	methods: {
		inputType(type) {
			const { EMAIL, TEXT, TYPE, data: { tags } = {} } = this
			return (tags.find(x => x.startsWith(TYPE)) || '')
				.replace(TYPE, '') === EMAIL
				? EMAIL
				: type || TEXT
		},
		async termsAndConditions() {
			this.termsLoading = true
			const query = 'CONTENT_TEXT_BY'
			const variables = { slug: 'terms_and_conditions' }
			this.termsLoading = false
			const src = (((await Apollo.query('CONTENT_TEXT_BY', variables)) || [])[0] || {}).html || ''
			return { src }
		},
		open_url(url) {
			if (url) window.open(url, '_blank').focus()
		},
		required(str) {
			const { data: { isRequired = false } = {} } = this
			return isRequired
				? str
				: ''
		},
		confirmationElementId(id) {
			const { isWithConfirmation } = this
			return isWithConfirmation
				? null
				: id
		},
		confirmationLabel(label) {
			const { isWithConfirmation } = this
			return isWithConfirmation
				? `${label} (confirmation)`
				: label
		},
		confirmation(x) {
			const { isWithConfirmation } = this
			return isWithConfirmation
				? `${x}Confirmation`
				: x
		},
	},
	updated() {
		this.value = null
	}
}
