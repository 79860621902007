<template>
	<span>
		<div
			v-for="group in contentTextsGroups"
			:class="css(group)"
			class="display"
		>
			<div
				v-for="entry in getFormElement(group)"
			>
				<component
					:is="getElementType(entry)"
					:data="entry"
					:context="context"
					@tap="tap"
				/>
			</div>
		</div>
			<div class="line buttons">
				<ui-button type="login" color="red" v-hammer:tap="() => back()">Retour</ui-button>
			</div>
	</span>
</template>

<script>
	import { mixinForms } from '@/js/mixins/forms'

	export default {
		name: 'displaySignUp',
		mixins: [ mixinForms ],
		methods: {
			back() {
				this.$store.dispatch('removeAccessToken')
				this.$router.push({ name: 'start' })
			},
		}
	}
</script>

<style lang="stylus">
	@import '../../assets/css/forms-base'

	.form-content-text-themed
		color white
	.form-btn-themed
		background-color #61789E
		margin-bottom 20px
	.signup-title
		color #2F7BB5
		text-transform uppercase
		font-weight bold
		font-size 1.3em
		margin 0 0 20px 0
	.title
		text-align center
		font-size 1.1em
		font-weight bold
		color white
		padding-bottom 10px
		border-bottom 2px solid white
		margin-bottom 24px
	.GRP-1
		background-color white
		border-radius 5px
		padding 16px 16px 4px 16px
		box-shadow 0px 3px 6px alpha(#000, 30%)
		> div
			margin 0 0 12px 0
	.news-emails
		color white
		margin-top 20px
</style>
