import ContentText from '@/components/form/form-elements/content-text'
import ContentFormAcceptinput from '@/components/form/form-elements/content-form-acceptinput'
import ContentFormSelectinput from '@/components/form/form-elements/content-form-selectinput'
import ContentFormTextinput from '@/components/form/form-elements/content-form-textinput'
import ContentFormPasswordinput from '@/components/form/form-elements/content-form-passwordinput'
import formBtn from '@/components/form/form-elements/form-btn'
import formFancybox from '@/components/form/form-elements/form-fancybox'
import { allBeginsWith } from '@/js/utils/utils'
import { mapGetters } from 'vuex'

export const mixinForms = {
	components: {
		ContentText,
		ContentFormAcceptinput,
		ContentFormSelectinput,
		ContentFormTextinput,
		ContentFormPasswordinput,
		formBtn,
		formFancybox
	},
	props: [ 'data', 'context' ],
	data() {
		const BTN = 'BTN-'
		const FANCYBOX = 'FANCYBOX-'
		const FORM = 'FORM-'
		const GRP = 'GRP-'
		return {
			BTN,
			FANCYBOX,
			FORM,
			GRP,
			submitDisabled: false,
		}
	},
	computed: {
		...mapGetters([ 'user' ]),
		step() {
			const { user: { manageSignUp: { step } = {} } = {} } = this
			return step
		},
		progressionId() {
			const { $store: { getters: { progressionId } = {} } = {} } = this
			return progressionId
		},
		formNumber() {
			const { FORM, step: { tags = [] } = {} } = this
			return parseInt((allBeginsWith(tags, FORM)[0] || '').replace(FORM, ''))
		},
		contentTextsGroups() {
			const { GRP, step: { contentTexts = [] } = {} } = this
			let groups = []
			let group = []
			let lastGroupName = null
			const pushGroup = () => {
				if (group.length) groups = [ ...groups, { grp: lastGroupName, elements: group } ]
			}
			contentTexts
				.forEach(element => {
					let { tags = [] } = element
					const groupName = allBeginsWith(tags, GRP)[0]
					if (!groupName) {
						pushGroup()
						groups = [ ...groups, { grp: lastGroupName, elements: [ element ] } ]
					} else if (groupName === lastGroupName) {
						group = [ ...group, element ]
					} else {
						pushGroup()
						group = [ element ]
						lastGroupName = groupName
					}
				})
			pushGroup()
			return groups
		},
		formElementsGroups() {
			const { GRP, step: { formElements = [] } = {} } = this
			let groups = []
			let group = []
			let lastGroupName = null
			const pushGroup = () => {
				if (group.length) groups = [ ...groups, { grp: lastGroupName, elements: group } ]
			}
			formElements
				.forEach(element => {
					let { id:formElementId, entry, entry: { tags = [] } = {} } = element
					Object.assign(entry, {
						formElementId
					})
					const groupName = allBeginsWith(tags, GRP)[0]
					if (!groupName) {
						pushGroup()
						groups = [ ...groups, { grp: lastGroupName, elements: [ entry ] } ]
					} else if (groupName === lastGroupName) {
						group = [ ...group, entry ]
					} else {
						pushGroup()
						group = [ entry ]
						lastGroupName = groupName
					}
				})
			pushGroup()
			return groups
		},
		formElements() {
			const { step: { formElements = [] } = {} } = this
			return formElements
				.map(x => x.entry)
		}
	},
	methods: {
		tap(func) {
			if (this[func]) this[func]()
		},
		css(group) {
			const { grp } = group
			return grp || ''
		},
		getFormElement(group) {
			const { elements = [] } = group
			return elements
		},
		getExtra(entry) {
			const { tags = [] } = entry
			if (!tags || !tags.length) return null
			const { BTN, FANCYBOX } = this
			const func = tags
				.reduce((a, c) => {
					const [ , prefix, extra ] = c.match(/^(.+\-)(.+)/)
					if ([ BTN, FANCYBOX ].includes(prefix) && !a) return extra
					return a
				}, '')
			if (this[func]) return this[func]()
			return null
		},
		getElementType(entry) {
			if (!entry) return
			const { BTN, FANCYBOX } = this
			let { elementType = '', tags = [] } = entry
			if (allBeginsWith(tags, BTN).length) elementType = 'form-btn'
			if (allBeginsWith(tags, FANCYBOX).length) elementType = 'form-fancybox'
			return elementType
				.replace(/::/g, '-')
				.toLowerCase()
		},
		isWithConfirmation(entry) {
			const { withConfirmation = false } = entry
			return withConfirmation
		}
	}
}
