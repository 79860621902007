<template>
  <div class='page page-signup'>
    <div v-if="loading" class="loading"></div>
		<ValidationObserver
			ref="signUp"
			v-slot="{ handleSubmit }"
			tag="div"
			:key="step_id"
      v-else
			class="validation-observer"
		>
			<FormsSignUp
				v-if="isFormElements"
				:context="getContext"
				class="form"
			/>
			<DisplaySignUp
				v-if="isContentTexts"
				:context="getContext"
				class="form"
			/>
		</ValidationObserver>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'
  import {ValidationObserver, ValidationProvider} from 'vee-validate'
  import Apollo from "@/apollo/apollo-client"
  import {isAllPropertyHasValue} from '@/js/utils/utils'
	import {LANGUAGE} from "@/js/factory/language"
	import FormsSignUp from '@/components/form/forms-signup'
	import DisplaySignUp from '@/components/form/display-signup'
	import { removeAccessToken } from '@/js/jwt/jwt'
	// import HomeBackground from '@/components/home-background'

	export default {
    name: 'page-signup',
    components: {
			DisplaySignUp,
			FormsSignUp,
			// HomeBackground,
      ValidationProvider,
      ValidationObserver
    },
    data() {
      return {
        loading: true,
        // email: null,
        // password: null,
        // passwordConfirmation: null,
        // surname: null,
        errorData: null
      }
    },
    directives: {
      errorUpdate: {
        bind: function (el, binding, vnode) {
          const key = Object.keys(binding.modifiers).shift()
          if (key) binding.value(key)
        }
      },
      errorClear: {
        bind: function (el, binding, vnode) {
          const key = Object.keys(binding.modifiers).shift()
          if (key) binding.value(key)
        }
      }
    },
    computed: {
      ...mapGetters(['clientUserSchemaId']),
			...mapGetters(['app', 'user']),
			...mapState({
				loggedIn: state => state.user.loggedIn,
				subjectsToH: state => state.schema.data && state.schema.data.subjectsToH
					? state.schema.data.subjectsToH
					: null,
				schemaId: state => state.schema.data
					? state.schema.data.id
					: null,
				manageUser: state => state.user.manageUser,
				manageSignUp: state => state.user.manageSignUp,
				isFormElements: state => {
					const { user: { manageSignUp: { step: { formElements = null } = {} } = {} } = {} } = state
					return !!formElements
				},
				isContentTexts: state => {
					const { user: { manageSignUp: { step: { contentTexts = null } = {} } = {} } = {} } = state
					return !!contentTexts
				},
				getContext: state => state.user || {}
			}),
			step_id() {
				if (this.manageSignUp && this.manageSignUp.step && this.manageSignUp.step.id)
					return this.manageSignUp.step.id
				return null
			},
			language() {
				return LANGUAGE.getData()
			},
    },
    methods: {
      clearError(field) {
        this.errorData = null
      },
      setError(field) {
        this.errorData = field
      },
      // async signUp() {
      //   const {email, password, passwordConfirmation, surname, schemaId} = this
      //   if (!isAllPropertyHasValue({email, password, passwordConfirmation, surname})) return
      //   const input = {
      //     email,
      //     password,
      //     passwordConfirmation,
      //     data: JSON.stringify({'surname': surname}),
      //     schemaId: this.clientUserSchemaId
      //   }
      //   const variables = {input}
      //   if (await Apollo.mutate('SIGNUP', variables)) {
      //     await this.$router.push({name: 'signup-sent'})
      //   }
      // }
    },
    async mounted() {
      const id = this.clientUserSchemaId
      const value = await Apollo.query('SCHEMA', {id})
			await this.$store.dispatch('schemaSet', {value})
			const { manageSignUp: { func: { start } = {} } = {} } = this
			if (start) {
				const { step } = await start() || {}
				if (!step) await this.$router.push({ name: 'start' })
				await this.$store.dispatch('setFormStep', { step })
			}
      this.loading = false
    }
  }
</script>

<style lang='stylus' scoped>
  @import '../../assets/css/variables'

.loading
  width 100%
  height 300px
  background url('../../assets/img/loader.svg') center center no-repeat
  background-size 80px

</style>
