<template>
	<div class="page page-forgot-sent">
		<div class="page-title">Mot de passe oublié</div>
		<form class="form">

			<p class="text">Un courriel de réinitialisation de mot de passe vous a été envoyé.</p>

			<div class="buttons">
				<ui-button :to="{ name: 'start' }" color="green">Ok</ui-button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'page-forgot-sent',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([  ])
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.view-login .ticket .page.page-forgot-sent
	.text
		font-size 1.8rem
		text-align center
		color #2e7bb5
		font-weight bold
		margin 0 0 30px 0
	.buttons
		flex center center
		>>> .ui-button
			margin 0 auto


</style>
