<template>
	<ValidationObserver
		ref="signUp"
		v-slot="{ handleSubmit }"
		tag="div"
	>
    <form
			class="form"
			method="post"
			ref="form"
			@submit.prevent="handleSubmit(submit)"
			@keyup.enter='handleSubmit(submit)'
		>
			<div
				v-for="group in formElementsGroups"
				:class="css(group)"
			>
				<div
					v-for="entry in getFormElement(group)"
				>
					<component
						:is="getElementType(entry)"
						:data="entry"
						@tap="tap"
					/>
					<component
						:is="getElementType(entry)"
						v-if="isWithConfirmation(entry)"
						:is-with-confirmation="true"
						:data="entry"
						@tap="tap"
					/>
				</div>
			</div>
			<template
				v-if="[1].includes(formNumber)"
			>
				<div class="line buttons">
					<ui-button type="login" color="red" v-hammer:tap="() => back()">Retour</ui-button>
					<ui-button type="login" color="green" :loading="app.loading && !termsLoading" v-hammer:tap="() => handleSubmit(submit)">Confirmer</ui-button>
				</div>
			</template>
			<button type="submit"/>
		</form>
  </ValidationObserver>
</template>

<script>
	import { mixinForms } from '@/js/mixins/forms'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { _apollo } from '@/js/managers/_apollo'
	import { mapGetters } from 'vuex'
	import { removeAccessToken } from '@/js/jwt/jwt'
  import { getValueOnPath, removeStart, capFirst } from '@/js/utils/utils'
  // import DialogBox from '@/components/game/dialog-box'
	import Apollo from '@/apollo/apollo-client'

	export default {
		name: 'formsSignUp',
		mixins: [ mixinForms ],
    data() {
		  return {
		    fancybox: {
		      termsAndConditions: {
		        url: 'pdf/dummy.pdf'
          }
        },
		    termsAndConditionsText: null,
        termsLoading: false
      }
    },
		components: {
      // DialogBox,
			ValidationObserver,
			ValidationProvider
		},
		computed: {
			...mapGetters([ 'app', 'game', 'user' ]),
			tutoUserData() {
				return this.app.tuto_user_data
			}
		},
		methods: {
			capFirst,
			async back() {
				// await removeAccessToken()
				// await this.$store.dispatch('removeUser')
				await this.$router.push({ name: 'start' })
			},
			start() {
				this.$router.push({ name: 'home' })
			},
			async onError(error) {
				let { message } = error
				message = removeStart(message, ':')
				await this.$store.dispatch('add_notification', { status: 'error', message })
				this.submitDisabled = false
			},
			async submitAnswers({ variables }) {
				const { onError } = this
				if (!variables) return null
				let mutation = 'SUBMIT_FORM'
				const { progression: { id } = {} } = await _apollo({ mutation, variables, onError }) || {}
				if (!id) {
					this.submitDisabled = false
					return
				}
				mutation = 'COMPLETE_STEP'
				variables = { progressionId: id }
				return await _apollo({ mutation, variables, onError })
			},
			async submit() {
				const { onError, submitDisabled, tutoUserData } = this
				if (submitDisabled) return
				this.submitDisabled = true
				const { progressionId, $refs: { signUp = null } = {} } = this
				if (!progressionId) {
					this.submitDisabled = false
					return
				}
				let answers = []
				const { $refs: { form = [] } = {} } = this
				for (let element of form) {
					const { value, dataset: { id:formElementId } = {} } = element
					if (formElementId) {
						answers = [ ...answers, {
							formElementId,
							data: [value]
						}]
					}
				}
				let variables = { progressionId, answers }
				let loop = true
				do {
					let mutation = 'SUBMIT_FORM'
					const { progression: { id } = {} } = await _apollo({ mutation, variables, onError }) || {}
					if (!id) {
						this.submitDisabled = false
						return
					}
					mutation = 'COMPLETE_STEP'
					variables = { progressionId: id }
					const result = await _apollo({ mutation, variables, onError })
					if (!result) {
						this.submitDisabled = false
						return
					}
					const { progression: { clientUser: { onboarding = null } = {} } = {}, gameCompleted, nextStep: { content:step } = {} } = result
					if (gameCompleted && onboarding == 'is_validated') {
						await this.$store.dispatch('get_user')
						await this.$router.push({ name: 'home' })
						loop = false
					} else {
						let { formElements = [] } = step
						answers = []
						if (formElements && formElements.length && tutoUserData) {
							answers = formElements
								.reduce((a, { id:formElementId, entry: { slug } = {} }) =>
									formElementId
									&& slug
									&& tutoUserData[slug]
										? [ ...a, {
											formElementId,
											data: [ tutoUserData[slug] ]
										}]
										: a, [])
						}
						if (formElements.length && formElements.length === answers.length) {
							variables = { progressionId, answers }
						} else {
							if (step) await this.$store.dispatch('setFormStep', {step})
							await this.$store.dispatch('get_user')
							loop = false
						}
					}
				} while (loop)
				if (signUp) signUp.reset()
				this.submitDisabled = false
				this.formError = null
			},
      open_url(url) {
        if (url) {
          let win = window.open(url, '_blank')
          win.focus()
        }
        this.save()
      }
		}
	}
</script>

<style lang="stylus" scoped>
	@import '../../assets/css/forms-base'

	.form-content-text-themed
		color white
	.form-btn-themed
		background-color #61789E
		margin-bottom 20px
	.title
		text-align center
		font-size 1.1em
		font-weight bold
		color white
		padding-bottom 10px
		border-bottom 2px solid white
		margin-bottom 24px
	.text
		color white
	.GRP-1
		background-color white
		border-radius 5px
		padding 16px 16px 4px 16px
		box-shadow 0px 3px 6px alpha(#000, 30%)
		> div
			margin 0 0 12px 0
</style>
