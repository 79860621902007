<template>
	<div class="page page-menu">
		<div class="logo"></div>
		<div class="menu">
			<ui-button color="dark" v-hammer:tap="() => set_page('world')">Jouer</ui-button>
			<ui-button color="dark" v-hammer:tap="() => set_page('login')">Connexion</ui-button>
			<ui-button color="dark" v-hammer:tap="() => set_page('signup')">Créer un compte</ui-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'page-menu',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([  ])
	},
	methods: {
		set_page(name) {
			if (name === 'world') this.$store.commit('EDIT_APP_STATE', { main_loader: true })
			this.$router.push({ name })
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.page-menu
	// height 100%
	.logo
		width 100%
		height 200px
		margin 0 0 32px 0
		background url(../../assets/img/logo.svg) bottom center no-repeat
		background-size auto 90%
	.menu
		width 100%
		.ui-button
			display block
			width 100%
			margin 0 0 16px 0
			box-shadow 0px 3px 6px alpha(#000, 30%)
			&:last-child
				margin 0
			&.inactive
				box-shadow none
			>>> .button
				font-size 1.8rem
				font-weight 700
				text-transform uppercase

</style>
